h1 {
    margin:0;
}

/* .top-slice {
    display: flex;
    flex-wrap: wrap;
    padding-left: 20%;
    padding-right: 20%;
    padding-top:50px;
    padding-bottom:50px;
    align-items:center;
    background-color: #37a7e5;
} */

/* .top-slice-left {
    flex:1;
    padding-right:10px;
    margin-top:0;
} */

/* .top-slice-right {
    flex:1;
    padding-left:10px;
    align-items:center;
} */

.ad-perf-revenue-attribution-img {
    width:100%;
    min-width: 300px;
}

/* .summary-slice {
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
} */

/* .summary-tab {
    flex-basis: 65%;
} */

/* .summary-tab-img {
    width:90%;
    padding-left:7%;
    padding-right:3%;
} */

.summary-text {
    flex-basis: 35%;
    text-align: left;
}

.summary-text-header {
    width:85%;
    padding-left:3%;
    padding-right:12%;
}

.summary-text-body {
    width:85%;
    padding-left:3%;
    padding-right:12%;
}

@media (max-width: 768px) {
    .top-slice {
        padding: 0; /* Remove padding on screens with a maximum width of 768px */
    }
    .top-slice-left {
        padding: 0;
    }
    .top-slice-right {
        padding: 0;
    }
  }
  
