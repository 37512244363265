.animate-scroll {
    width: 800%;
    animation: scroll 70s linear infinite;
}


.brand {
    display: flex;
    flex: 1;
    padding: 0 1.3% 0 1.3%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-50%); /* when half of the brands*2 are through */
    }
}

@media (min-width: 481px) {
    .animate-scroll {
        width: 600%;
    }
}

/* For screens wider than 800px */
@media (min-width: 769px) {
    .animate-scroll {
        width: 400%;
    }
}

@media (min-width: 1025px) {
    .animate-scroll {
        width: 280%;
    }
}

@media (min-width: 1801px) {
    .animate-scroll {
        width: 220%;
    }
}