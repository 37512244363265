@font-face {
  font-family: 'Quadran';
  src: url('./fonts/Quadran-Bold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Phantom-Sans';
  src: url('./fonts/phantom-sans-semibold.ttf') format('truetype'),
       url('./fonts/phantom-sans-light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Quadran';
}

p {
  font-family: 'Phantom-Sans';
  /* font-weight: 'light'; */
}

.nav-container a {
  text-decoration: none;
  color: black;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
